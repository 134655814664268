import BUY3_GET1_FREE_BUNDLES_DATA from "./buy3-get1-free-bundles"

const BUNDLES_DATA = {
  // Neapolitan Bundle
  "gid://shopify/Product/7502990639296": {
    type: "bundles",
    title: "Neapolitan Bundle",
    variants: [
      {
        id: "gid://shopify/ProductVariant/43749167071424",
        sellingPlansImg: {
          horizontal: "neapolitan-bundle",
          vertical: "neapolitan-bundle",
        },
      },
    ],
    img: ["neapolitan"],
    collection: "proteins",
    paymentOptions: 2,
    noRegularText: true,
    saveText: "(Save Over 25%)",
    freeGift: false,
    references: false,
    tabs: ["berry-green-protein", "vanilla-protein", "chocolate-protein"],
    sellingPlans: [
      {
        sellingPlanId: 1946550464,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1946583232,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1946616000,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
  },
  // Workout Bundle - Workout Bundle
  "gid://shopify/Product/7345480204480": {
    type: "bundles",
    title: "Workout Bundle (+ Free Protein)",
    variants: [
      {
        id: "gid://shopify/ProductVariant/43141487755456", // Classic
        hasOptions: true,
        sellingPlansImg: {
          cover: "workout-bundle-classic-option",
          horizontal: "workout-bundle-classic-option-payment-horizontal",
          vertical: "workout-bundle-classic-option-payment-vertical",
        },
      },
      {
        id: "gid://shopify/ProductVariant/43141487788224", // Vanilla
        hasOptions: true,
        sellingPlansImg: {
          cover: "workout-bundle-vanilla-option",
          horizontal: "workout-bundle-vanilla-option-payment-horizontal",
          vertical: "workout-bundle-vanilla-option-payment-vertical",
        },
      },
      {
        id: "gid://shopify/ProductVariant/43141487853760", // Chocolate
        hasOptions: true,
        sellingPlansImg: {
          cover: "workout-bundle-chocolate-option",
          horizontal: "workout-bundle-chocolate-option-payment-horizontal",
          vertical: "workout-bundle-chocolate-option-payment-vertical",
        },
      },
    ],
    img: ["workout-promo"],
    collection: "proteins",
    freeGift: 44.95,
    freeGiftType: "protein",
    noRegularText: true,
    saveText: "(Save Over 23%)",
    references: false,
    tabs: ["chocolate-protein", "vanilla-protein", "full-body-collagen", "rock-and-restore"],
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 1946419392,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1946484928,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1946517696,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
  },

  ...BUY3_GET1_FREE_BUNDLES_DATA,

  // 4 Pillars of Health Bundle
  "gid://shopify/Product/7004128313536": {
    type: "bundles",
    title: "4 Pillars of Health Bundle",
    variants: [{ id: "gid://shopify/ProductVariant/41240319426752" }],
    img: ["pillars"],
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 12.95,
    freeGiftType: "gift",
    noRegularText: false,
    saveText: "(Save Over 13%)",
    references: false,
    tabs: ["rock-and-restore", "vanilla-protein", "whole-sleep", "serenicalm"],
  },
  // I love Vanilla Protein Bundle
  "gid://shopify/Product/7004126970048": {
    type: "bundles",
    title: "I ❤ Vanilla Protein Bundle",
    variants: [{ id: "gid://shopify/ProductVariant/41240315855040" }],
    img: ["vanilla-promo-lbnmrl"],
    quote: "This is literally the BEST vanilla protein powder I have ever tasted, and I'm super picky!",
    quoteAuthor: "Heather M",
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 14.95,
    freeGiftType: "gift",
    noRegularText: false,
    saveOfferText: "(Save Over 10%)",
    saveText: "(Save Over 10%)",
    optionDescription: "Stock up and Save 10% when you buy 3 and get a FREE 30-day challenge calendar tracker.",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // I love Chocolate Protein Bundle
  "gid://shopify/Product/7396508893376": {
    type: "bundles",
    title: "I ❤ Chocolate Protein Bundle",
    variants: [{ id: "gid://shopify/ProductVariant/43274468491456" }],
    img: ["chocolate-promo-lbnmrl"],
    quote:
      "It's so good - it just tastes like chocolate! What I usually don't like about protein powders is that they're too grainy - but in this you can really taste the flavor and you're not distracted by the texture - it just tastes likes a smoothie.",
    quoteAuthor: "Adei G",
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 14.95,
    freeGiftType: "gift",
    noRegularText: false,
    saveOfferText: "(Save Over 10%)",
    saveText: "(Save Over 10%)",
    optionDescription: "Stock up and Save 10% when you buy 3 and get a FREE 30-day challenge calendar tracker.",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Berry Green Protein Bundle
  "gid://shopify/Product/7004126478528": {
    type: "bundles",
    title: "Berry Green Protein Bundle",
    variants: [{ id: "gid://shopify/ProductVariant/41240314347712" }],
    img: ["bgp-promo-lbnmrl"],
    quote:
      "I love that the Berry Green Protein is delicious just with water when I’m in a hurry or I can put it in my smoothies - and I’m going to bake with it next!",
    quoteAuthor: "Holly L",
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 14.95,
    saveOfferText: "(Save Over 10%)",
    saveText: "(Save Over 10%)",
    noRegularText: false,
    freeGiftType: "gift",
    optionDescription: "Stock up and Save 10% when you buy 3 and get a FREE 30-day challenge calendar tracker.",
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Rock and Restore Bundle
  "gid://shopify/Product/7004127494336": {
    type: "bundles",
    title: "Rock and Restore Bundle",
    variants: [{ id: "gid://shopify/ProductVariant/41240317132992" }],
    img: ["rock-and-restore-promo-lbnmrl"],
    quote:
      "I notice a difference in my recovery time for my workouts, and my muscle definition seems to be popping more.",
    quoteAuthor: "Amanda M.",
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 14.95,
    freeGiftType: "gift",
    noRegularText: false,
    saveOfferText: "(Save Over 10%)",
    saveText: "(Save Over 10%)",
    optionDescription: "Stock up and Save 10% when you buy 3 and get a FREE Stop, Drop and Betty Rock shaker bottle.",
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Full Body Collagen Bundle
  "gid://shopify/Product/7004127854784": {
    type: "bundles",
    title: "Full Body Collagen Bundle",
    variants: [{ id: "gid://shopify/ProductVariant/41240318050496" }],
    img: ["fbc-promo-lbnmrl"],
    quote:
      "I have used other collagens in the past and this one is by far the cleanest and the smoothest - it’s completely tasteless and it’s very versatile!",
    quoteAuthor: "Stephenie M",
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 14.95,
    freeGiftType: "gift",
    noRegularText: false,
    saveOfferText: "(Save Over 10%)",
    saveText: "(Save Over 10%)",
    optionDescription: "Stock up and Save 10% when you buy 3 and get a FREE All or Something grocery tote.",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
}

export default BUNDLES_DATA
